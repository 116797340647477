class SequenceItem {
  constructor(min, max, config) {
    this.min = min;
    this.max = max;
    this.config = config;
    this.idle = false;
  }

  onElapsedMin(callback) {
    return setTimeout(() => {
      this.idle = true;
      callback();
    }, this.min);
  }

  onElapsedMax(callback) {
    return setTimeout(callback, this.max);
  }
}

/**
 * !POC!
 * SequenceRunner attempts to provide an API for making smoother transitions between states,
 * so that users would not see flickering.
 *
 * Performing something very fast is good, but when we provide a visual feedback to users,
 * like disabling button up until request finishes and then making it active again - making
 * all those UI changes too fast would bring discomfort to users.
 *
 * SequenceRunner allows to run actions in more slow-paced manner one after another to reduce
 * flickering feeling or finish some animation. It also allows to run something when waiting
 * for a state transition takes more time than expected.
 */
class SequenceRunner {
  /**
   * @param {Number} min (ms) default minimum amount of time before going to next action in sequence
   * @param {Number} max (ms) default maximum amount of time for action to be active
   */
  constructor({ min = 1000, max = 1000 * 60 }) {
    this.queue = [];
    this.timeoutID = null;
    this.defaultMin = min;
    this.defaultMax = max;
  }

  /**
   * @param {Number} min (ms) minimum amount of time before going to next action in sequence
   * @param {Number} max (ms) maximum amount of time for action to be active
   * @param {Function} start callback to execute when action is "activated"
   * @param {Function} end callback to execute when action is "deactivated"
   *   (either by transition to next action in sequence or when maximum time is elapsed)
   * @return {void}
   */
  add({ min = this.defaultMin, max = this.defaultMax, start, end }) {
    const item = new SequenceItem(min, max, { start, end });
    this.queue.push(item);
    if (this.current.idle) {
      this.#transition();
    }
    if (this.running) {
      return;
    }
    this.#startMin();
  }

  stop() {
    this.queue = [];
    clearTimeout(this.timeoutID);
  }

  get current() {
    return this.queue[0];
  }

  get next() {
    return this.queue[1];
  }

  get running() {
    return !!this.timeoutID;
  }

  #startMin() {
    this.current.config.start();
    this.timeoutID = this.current.onElapsedMin(() => {
      if (this.next) {
        this.#transition();
      } else {
        this.#startMax();
      }
    });
  }

  #startMax() {
    this.timeoutID = this.current.onElapsedMax(() => {
      this.current.config.end();
      this.timeoutID = null;
      this.queue.shift();
    });
  }

  #transition() {
    this.current.config.end();
    clearTimeout(this.timeoutID);
    this.queue.shift();
    this.#startMin();
  }
}

export default SequenceRunner;
