import { Controller } from 'stimulus';
import { SequenceRunner } from '@/js/util';

export default class extends Controller {
  static targets = ['inProgress', 'succeeded', 'failed'];

  connect() {
    this.sequenceRunner = new SequenceRunner({ min: 1000, max: 1000 * 60 });
  }

  disconnect() {
    this.sequenceRunner.stop();
  }

  update({ detail }) {
    const { state } = detail;
    this.state = state;
  }

  set state(value) {
    const [target, sequenceConfig] = this.#resolveTarget(value);
    this.sequenceRunner.add({
      ...sequenceConfig,
      start: () => target.classList.remove('!hidden'),
      end: () => target.classList.add('!hidden'),
    });
  }

  #resolveTarget(stateKey) {
    switch (stateKey) {
      case 'in-progress':
        return [this.inProgressTarget, {}];
      case 'succeeded':
        return [this.succeededTarget, { max: 1000 * 3 }];
      case 'failed':
        return [this.failedTarget, { max: 1000 * 3 }];
      default:
        throw new Error(`unknown status: ${stateKey}`);
    }
  }
}
