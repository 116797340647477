const printContent = (editor) => {
  let iframe = document.getElementById('print-dialog');

  if (!iframe) {
    iframe = document.createElement('iframe');
    iframe.id = 'print-dialog';
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';
    document.body.appendChild(iframe);
  }

  const iframeContent = prepareIframeContent(editor.view.dom.innerHTML);
  const doc = iframe.contentWindow.document;
  doc.open();
  doc.write(iframeContent);
  doc.close();
};

const prepareIframeContent = (content) => {
  const styles = document.querySelector('link[rel="stylesheet"][href*="application"]').outerHTML;

  return `<!DOCTYPE html>
<html>
<head>
  <title>Print</title>
  ${styles}
  <style>
    @page { margin: 0; size: letter; }
  </style>
  <script>
    addEventListener("DOMContentLoaded", (event) => {
      const selector = '[data-type="taskList"] [data-checked="true"] > label > input';
      const chekedItems = document.querySelectorAll(selector);
      chekedItems.forEach((el) => el.checked = true);
      window.print();
    });
  </script>
</head>
<body class="print-dialog">
  <div class="tiptap-editor__page">${content}</div>
</body>
</html>`;
};

export default {
  'print:open-dialog': printContent,
};
