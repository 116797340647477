/* global Popper */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['trigger', 'indicator', 'content'];

  connect() {
    this.instance = Popper.createPopper(this.triggerTarget, this.contentTarget, {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
  }

  disconnect() {
    this.instance.destroy();
  }

  /**
   * @param {MouseEvent} e
   */
  toggle(e) {
    const { target } = e;

    if (this.triggerTarget.contains(target)) {
      this.#visible = !this.#visible;
      return;
    }

    this.#visible = false;
  }

  hide() {
    this.#visible = false;
  }

  get #visible() {
    return !this.contentTarget.classList.contains('hidden');
  }

  set #visible(value) {
    if (value === this.#visible) {
      return;
    }

    if (this.hasIndicatorTarget) {
      this.indicatorTarget.classList.toggle('-scale-y-100', value);
    }

    this.contentTarget.classList.toggle('hidden', !value);

    this.instance.setOptions((options) => ({
      ...options,
      modifiers: [...options.modifiers, { name: 'eventListeners', enabled: value }],
    }));
  }
}
